export function smoothScroll( id: string, offset: number = 90 ) {
    const element = document.getElementById( id );
    if (!element) {
        return
    }
    const top = window.scrollY + element.getBoundingClientRect().top - offset;
    window.scrollTo( {
        behavior: 'smooth',
        top
    } );
}
