import * as React from "react";
import { FC } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { fadeInParent, hVariants, pVariants, zoomOut } from "../_animationVariants";
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { makeButtonClass } from "../cssClasses";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { imgAlt } from "../../utilities";
import t from "../../i18n";

interface IContactSection {

}

const ContactSection: FC<IContactSection> = () => {
    const [ ref, inView ] = useInView( {
        rootMargin : "0px 0px -250px 0px",
        triggerOnce: true,
    } )
    const { contact } = useStaticQuery( graphql`{
        contact: file(relativePath: {eq: "contact-section.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
    ` )
    return (
        <motion.section ref={ ref } className={ "lg:flex--center-middle overflow--hidden relative bg--overlay" }>
            <motion.div variants={ zoomOut } animate={ inView ? "in" : "out" } className={ "absolute left--0 top--0 w--100 h--100 flex--center z---1" }>
                <GatsbyImage className={ "w--100 h--100" }
                             objectFit={ "cover" }
                             image={ contact.childImageSharp.gatsbyImageData }
                             alt={ imgAlt( contact.relativePath ) }
                />
            </motion.div>
            <motion.div className={ "py--huge px--xl lg:text--center text--gray-1 z--1" } variants={ fadeInParent } initial={ "init" } animate={ inView ? "in" : "out" }>
                <motion.h2 className={ "text--xl-1" } variants={ hVariants }>{ t( "Let's work together." ) }</motion.h2>
                <motion.p variants={ pVariants } className={ "max-w--40ch lg:mx--auto my--lg text--gray--1" }>{ t("Like what you see? Contact us and lets make your brand & website look awesome!") }</motion.p>
                <motion.div variants={ hVariants }>
                    <Link to={ "/contact" } className={ makeButtonClass( "accent" ).fill }>
                        { t( "Contact" ) }
                    </Link>
                </motion.div>
            </motion.div>
        </motion.section>
    )
}

export default ContactSection
