import * as React from "react"
import { FC } from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import LocalizedAnchorLink from "../components/ui/LocalizedAnchorLink";
import { useInView } from "react-intersection-observer";
import Layout from "../components/layout/Layout";
import HeadMeta from "../components/layout/HeadMeta";
import { motion } from "framer-motion"
import ContactSection from "../components/layout/ContactSection";
import { imgAlt, sanitizedHTML } from "../utilities";
import { fadeInParent, fadeLeft, fadeRight, hVariants, pVariants } from "../components/_animationVariants";
import { makeButtonClass } from "../components/cssClasses";
import { Translatable, TypeLocale } from "../types";
import homeData from "../data/homepage.json"
import t from "../i18n";

type HomePageData = {}
type HomePageContext = {
    locale: TypeLocale
}

const IndexPage: FC<PageProps<HomePageData, HomePageContext>> = ( { pageContext: { locale } } ) => {
    const l = locale
    const query = useStaticQuery( graphql`{
        intro: file(relativePath: {eq: "landing-header.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        branding: file(relativePath: {eq: "services/branding-ft.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        webDev: file(relativePath: {eq: "services/web-design-ft.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
    ` )
    return (
        <Layout>
            <HeadMeta title={ l === "en" ? "Home" : "Αρχική" }/>
            <section className={ "flex--center overflow--hidden h--v100 relative decoration-x" }>
                <div className={ "lg:col--7 flex--center-middle px--xl py--header" }>
                    <div>
                        <motion.h1 variants={ hVariants } className={ "text--xl-1" } style={ { whiteSpace: "pre-wrap" } } dangerouslySetInnerHTML={ sanitizedHTML( homeData.intro.title[l] ) }/>
                        <motion.p variants={ pVariants } className={ "max-w--60ch my--lg" }>{ homeData.intro.description[l] }</motion.p>
                        <motion.div variants={ hVariants } className={ "inline-flex mr--md" }>
                            <LocalizedAnchorLink className={ makeButtonClass( "accent" ).fill } to={ "branding" }>{ t( "Learn More" ) }</LocalizedAnchorLink>
                        </motion.div>
                        <motion.div variants={ hVariants } className={ "inline-flex" }>
                            <Link to={ "/projects" } className={ makeButtonClass( "accent" ).outline }>{ t( "View Projects" ) }</Link>
                        </motion.div>
                    </div>
                </div>
                <motion.div variants={ fadeLeft } className={ "col--5 none lg:block relative" }>
                    <GatsbyImage
                        className={ "h--v100" }
                        image={ query.intro.childImageSharp.gatsbyImageData }
                        imgStyle={ { objectFit: "cover" } }
                        alt={ imgAlt( query.intro.relativePath ) }
                    />
                </motion.div>
            </section>
            <ServiceSection data={ homeData.branding } l={ l } image={ query.branding }/>
            <ServiceSection data={ homeData.webDevelopment } l={ l } image={ query.webDev } reverse/>
            <ContactSection/>
        </Layout>
    )
}

export default IndexPage

interface ISection {
    slug: string
    title: Translatable
    description: Translatable
}

interface Section {
    data: ISection
    l: "en" | "el"
    image: any
    reverse?: boolean
}

const ServiceSection: FC<Section> = ( { data, l, image, reverse } ) => {
    const [ ref, inView ] = useInView( {
        rootMargin : "0px 0px -300px 0px",
        triggerOnce: true,
    } )
    return (
        <motion.section id={ data.title.en.replace( /\s+/g, '-' ).toLowerCase() } ref={ ref } className={ "min-h--v50 lg:flex--center overflow--hidden " } variants={ fadeInParent } initial={ "init" }
                        animate={ inView ? "in" : "out" }>
            <motion.div variants={ reverse ? fadeRight : fadeLeft } className={ "lg:w--50 relative" + (reverse ? " lg:order--2" : "") }>
                <GatsbyImage
                    className={ "lg:h--v75 h--slide" }
                    image={ image.childImageSharp.gatsbyImageData }
                    imgStyle={ { objectFit: "cover" } }
                    alt={ imgAlt( image.relativePath ) }
                />
            </motion.div>
            <div className={ "px--xl py--huge lg:px--huge" + (reverse ? " lg:order--1 lg:ml--auto" : "") }>
                <motion.h2 className={ "text--xl-1" } variants={ hVariants }>{ data.title[l] }</motion.h2>
                <motion.p variants={ pVariants } className={ "max-w--60ch my--lg" }>{ data.description[l] }</motion.p>
                <motion.div variants={ hVariants }>
                    <Link to={ `/${ data.slug }` } className={ makeButtonClass( "accent" ).fill }>{ t( "Learn More" ) }</Link>
                </motion.div>
            </div>
        </motion.section>
    )
}
