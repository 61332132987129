import * as React from "react";
import { FC, memo } from "react";
import { smoothScroll } from "./button/utils";

interface ILocalizedAnchorLink {
    to: string
}

const LocalizedAnchorLink: FC<ILocalizedAnchorLink> = memo( ( { children, to, ...rest } ) => {
    return (
        <button { ...rest } onClick={ async ( _ ) => {
            smoothScroll( to )
        } }>
            { children }
        </button>
    )
} )

export default LocalizedAnchorLink
